<template>
  <el-dialog
    title="模版制作"
    custom-class="design-form"
    :visible.sync="dialogVisible"
    :append-to-body="false"
    :modal="false"
    :destroy-on-close="true"
    :fullscreen="true">
    <el-container v-if="designForm.length > 0">
        <!-- 表单字段 -->
        <el-aside width="300px" class="design-form__left">
          <div>
            <ul class="design-filed__container" data-flex="main:justify">
              <li
                class="design-filed__items"
                data-flex="cross:center"
                :class="element.type"
                @click="handleAddItems(element)"
                v-for="(element, index) in filed"
                :key="index">
                <i :class="element.icon"></i>
                <span>{{element.name}}</span>
              </li>
            </ul>
          </div>
        </el-aside>
        <el-container>
          <el-header style="border-bottom: 1px solid #ebeef5" height="45px" data-flex="main:right cross:center">
            <el-link
            :underline="false"
            type="warning"
            class="ml-10"
            @click="handleClear"
            icon="el-icon-delete">
            清空
            </el-link>
            <el-link
            :underline="false"
            @click="handleSubmit"
            type="primary"
            class="ml-10">
            保存
            </el-link>
          </el-header>
          <el-main style="padding: 10px; position: relative;">
            <div class="design-body">
              <template v-for="(item, index) in designForm">
                <div
                  v-if="index == active"
                  class="design-body__content"
                  :style="{
                    backgroundColor: item.bgColor,
                    backgroundImage: `url(${item.bgImage})`,
                    backgroundRepeat: item.bgRepeat,
                    backgroundPosition: item.bgPosition,
                    backgroundSize: item.bgSize,
                  }"
                  :key="index">
                  <design
                    :selectWidget.sync="widgetFormSelect"
                    ref="design"
                    :config="activeTarget">
                  </design>
                </div>
              </template>
              <!-- other -->
              <div class="page-list">
                <div class="mb-10" v-for="(item, index) in designForm" :key="index">
                  <el-button @click="active = index" :type="active == index ? 'primary' : ''">
                    第{{ index + 1 }}页
                  </el-button>
                   <i
                    v-if="active != index"
                    style="font-size: 16px;cursor:pointer;"
                    class="el-icon-delete el-icon--right"
                    @click.stop="handleDeletePage(index)">
                  </i>
                </div>
              </div>
              <div class="asidebar">
                <div @click="handleCommonClick('addPage')"><el-button size="mini" type="primary" icon="el-icon-plus"></el-button></div>
                <div @click="handleCommonClick('erase')" v-if="widgetFormSelect.key && widgetFormSelect.type == 'text'"><el-button size="mini" type="warning" icon="ri-eraser-line"></el-button></div>
                <div @click="handleCommonClick('delete')" v-if="widgetFormSelect.key"><el-button size="mini" type="danger" icon="el-icon-delete"></el-button></div>
              </div>
              <div class="qrCode" v-if="qrcodeUrl != ''"><qrcode ref="qrcode" :value="qrcodeUrl" :options="{ width: 150 }"></qrcode></div>
            </div>
            <div data-flex="main:center" class="mt-10">320 * 568</div>
          </el-main>
        </el-container>
        <el-aside width="300px" class="design-form__right" data-flex>
          <el-container>
            <el-header class="aside-right__header" height="45px">
              <div class="tab" :class="{active: configTab == 'attribute'}" @click="configTab = 'attribute'">属性</div>
              <div class="tab" :class="{active: configTab == 'page'}" @click="configTab = 'page'">页面</div>
              <div class="tab" :class="{active: configTab == 'layer'}" @click="configTab = 'layer'">图层</div>
            </el-header>
            <el-main style="padding: 10px;">
              <attribute-cfg
                v-show="configTab == 'attribute'"
                :element="widgetFormSelect">
              </attribute-cfg>
              <page-cfg
                :config="designForm[this.active]"
                v-show="configTab == 'page'">
              </page-cfg>
              <div v-if="configTab == 'layer'">
                <div class="mb-10" v-for="(item, index) in activeTarget.list" :key="index">
                  <el-button style="width: 100%;" @click="handleClickLayer(item, index)" :type="widgetFormSelect.key == item.key ? 'primary' : ''">
                    图层{{ index + 1 }}({{item.name}})
                  </el-button>
                </div>
              </div>
            </el-main>
          </el-container>
        </el-aside>
      </el-container>
  </el-dialog>
</template>
<script>
import qrcode from '@chenfengyuan/vue-qrcode';
import cfg from '@/config';
import pageCfg from './pageCfg';
import attributeCfg from './attributeCfg';
import design from './design';
import * as filed from './config';

export default {
  components: {
    pageCfg,
    attributeCfg,
    design,
    qrcode,
  },
  data() {
    return {
      info: null,
      configTab: 'page',
      filed: filed.default,
      dialogVisible: false,
      widgetFormSelect: {}, // 当前选中的节点
      active: 0,
      designForm: [],
      defaultPage: {
        bgColor: '',
        bgImage: '',
        bgFile: null,
        bgRepeat: 'no-repeat',
        bgPosition: 'center center',
        bgSize: 'auto',
        list: [],
      },
    };
  },
  computed: {
    activeTarget() {
      return this.designForm[this.active];
    },
    qrcodeUrl() {
      if (this.$route.name === 'ticket-list') {
        if (this.info.id === '') return '';
        return `${cfg.giftUrl}/h5?ticket_id=${this.info.id}`;
      }
      return `${cfg.giftUrl}/h5?template_id=${this.info.id}`;
    },
  },
  watch: {
    dialogVisible: function change(val) {
      if (val) return;
      this.widgetFormSelect = {};
      this.active = 0;
      this.designForm = [];
    },
  },
  methods: {
    init(response) {
      this.info = response;
      if (response.content !== '') {
        this.designForm = JSON.parse(response.content);
      } else {
        this.designForm = [this.$baseDeepClone(this.defaultPage)];
      }
      this.dialogVisible = true;
    },
    async handleSubmit() {
      let response;
      const params = {
        id: this.info.id,
        ticket_id: this.info.id,
        content: JSON.stringify(this.designForm),
      };
      for (let i = 0; i < this.designForm.length; i += 1) {
        const target = this.designForm[i];
        if (target.bgFile && target.bgFile instanceof File) {
          const file = await this.upload(target.bgFile);
          target.bgImage = file[0].file;
          target.bgFile = null;
        } else {
          target.bgFile = null;
        }
        for (let j = 0; j < target.list.length; j += 1) {
          if (target.list[j].type === 'image' && target.list[j].file) {
            const file = await this.upload(target.list[j].file);
            if (file.length > 0) target.list[j].url = file[0].file;
            target.list[j].file = null;
          }
        }
      }
      if (!['template'].includes(this.$route.name)) {
        const other = this.$parent.form.find((r) => r.fieldName === 'other');
        const content = other.list.find((r) => r.fieldName === 'content');
        response = await this.$baseHttp.post('/ticket/template-content', params);
        if (!response) return;
        this.$message.success('操作成功');
        this.$set(content, 'value', JSON.stringify(this.designForm));
        return;
      }
      response = await this.$baseHttp.post('/template/content-save', params);
      if (!response) return;
      this.$message.success('操作成功');
      // this.dialogVisible = false;
    },
    handleClear() {
      this.active = 0;
      this.designForm = [this.$baseDeepClone(this.defaultPage)];
    },
    async upload(file) {
      return new Promise((resolve) => {
        const form = new FormData();
        form.append('files[0]', file);
        const config = {
          headers: { 'Content-Type': 'multipart/form-data' },
          timeout: 0,
        };
        this.$baseHttp.post('/attachment/upload', form, config).then((response) => {
          if (!response) return;
          resolve(response.data);
        });
      });
    },
    handleAddItems(element) {
      const target = this.$baseDeepClone(element);
      this.$set(target, 'z', this.activeTarget.list.length + 1);
      this.$set(target, 'key', `default_${Date.parse(new Date())}_${Math.ceil(Math.random() * 99999)}`);
      this.activeTarget.list.push(target);
    },
    handleCommonClick(type) {
      if (type === 'addPage') {
        this.designForm.push(this.$baseDeepClone(this.defaultPage));
      }
      if (type === 'delete') {
        const index = this.activeTarget.list.findIndex((r) => r.key === this.widgetFormSelect.key);
        if (index >= 0) {
          this.activeTarget.list.splice(index, 1);
          this.widgetFormSelect = {};
        }
      }
      if (type === 'erase') {
        this.$set(this.widgetFormSelect, 'text', this.widgetFormSelect.text.replace(/<\/?.+?>/g, '').replace(/ /g, ''));
      }
    },
    handleDeletePage(index) {
      this.designForm.splice(index, 1);
      this.active = index < this.active ? this.active - 1 : this.active;
    },
    handleClickLayer(item, index) {
      this.widgetFormSelect = item;
      setTimeout(() => {
        this.$refs.design[0].$refs.draggable[index].enabled = true;
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .design-form .el-dialog__header {
  padding: 10px;
  border-bottom: 1px solid #ebeef5;
  .el-dialog__headerbtn {
    top: 10px;
  }
}
::v-deep .design-form .el-dialog__body {
  padding: 0;
  height: calc(100vh - 45px);
  display: flex;
}
.design-body {
  width: 320px;
  height: 568px;
  margin: 0 auto;
  border: 1px solid #e7e7e7;
  box-sizing: initial;
  position: relative;
  .design-body__content {
    width: 100%;
    height: 100%;
  }
  .page-list {
    position: absolute;
    left: -100px;
    top: 10px;
  }

  .asidebar {
    width: 40px;
    position: absolute;
    right: -50px;
    color: #fff;
    top: 50%;
    transform: translateY(-50%);
    div {
      width: 40px;
      height: 40px;
      line-height: 40px;
      color: #fff;
      text-align: center;
      cursor: pointer;
      ::v-deep i {
        font-size: 16px;
      }
    }
  }
}
.design-form__left {
  border-right: 1px solid #ebeef5;
  padding: 10px;
  .design-filed__container {
    flex-wrap: wrap;
    padding: 0;
    margin-top: 0;
  }
  .design-filed__items {
    flex: 0 0 48%;
    font-size: 12px;
    line-height: 30px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    margin: 1%;
    color: #333;
    cursor: pointer;
    background: #f4f6fc;
    border: 1px dashed #fff;

    &:hover {
      border: 1px dashed var(--main-color-primary);
    }
    i {
      margin: 0 6px;
    }
  }
}
.design-form__right {
  border-left: 1px solid #ebeef5;
  padding: 0;
  .aside-right__header {
    line-height: 45px;
    padding: 0;
    display: flex;
    .tab {
      border-bottom: 1px solid #EBEEF5;
      flex: 1;
      text-align: center;
      cursor: pointer;

      &.active {
        border-bottom: 1px solid var(--main-color-primary);
      }
    }
  }
}

.qrCode {
  position: absolute;
  right: -150px;
  top: 10px;
}
</style>
