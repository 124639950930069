<template>
  <div style="height: 100%; background: linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px;">
    <vue-draggable-resizable
      v-for="element in config.list"
      :key="element.key"
      :w="element.width"
      :h="element.height"
      :x="element.left"
      :y="element.top"
      :z="element.z"
      :grid="[10, 10]"
      :draggable="draggable"
      ref="draggable"
      @dragging="onDrag"
      @resizing="onResize"
      @activated="onActivated(element)"
      :parent="true">
      <!-- 文字 -->
      <div
        class="animate__animated"
        :class="[
        'animate__'+element.animation,
        element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
        'animate__delay-'+element.animationDelay+'s',
        'animate__'+element.animationDuration]"
        :style="{
          opacity: element.opacity,
          backgroundColor: element.bgColor,
          fontSize: element.fontSize + 'px',
          color: element.fontColor,
          lineHeight: element.lineHeight + 'px',
          textAlign: element.textAlign,
        }"
        @dblclick="handleDbClick(element)"
        @blur="handleChangeText"
        :contenteditable="element.contenteditable || false"
        v-html="element.text"
        v-if="element.type == 'text'">
      </div>
      <!-- 块状 -->
      <div
        class="animate__animated"
        :class="[
        'animate__'+element.animation,
        element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
        'animate__delay-'+element.animationDelay+'s',
        'animate__'+element.animationDuration]"
        :style="{
          width: '100%',
          height: '100%',
          opacity: element.opacity,
          backgroundColor: element.bgColor,
        }"
        v-if="element.type == 'block'">
      </div>
      <!-- 图片 -->
      <div
        class="animate__animated"
        :class="[
        'animate__'+element.animation,
        element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
        'animate__delay-'+element.animationDelay+'s',
        'animate__'+element.animationDuration]"
        :style="{
          width: '100%',
          height: '100%',
          opacity: element.opacity,
        }"
        v-if="element.type == 'image'">
        <img
          :style="{
            objectFit: element.objFit,
          }"
          :src="element.url" />
      </div>
      <!-- 视频 -->
      <div
        class="animate__animated"
        :class="[
        'animate__'+element.animation,
        element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
        'animate__delay-'+element.animationDelay+'s',
        'animate__'+element.animationDuration]"
        :style="{
          width: '100%',
          height: '100%',
        }"
        v-if="element.type == 'video'">
        <video :src="element.url" :controls="element.controls" :loop="element.loop" :autoplay="element.autoplay"  style="width: 100%;height: 100%;"></video>
      </div>
      <!-- 音频 -->
      <div
        class="animate__animated audio"
        :class="[
        'animate__'+element.animation,
        element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
        'animate__delay-'+element.animationDelay+'s',
        'animate__'+element.animationDuration]"
        :style="{
          width: '100%',
          height: '100%',
        }"
        v-if="element.type == 'audio'">
        <audio :src="element.url" :loop="element.loop" :autoplay="element.autoplay"></audio>
      </div>
    </vue-draggable-resizable>
  </div>
</template>

<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';

export default {
  components: {
    VueDraggableResizable,
  },
  props: {
    config: {
      default: () => {},
    },
    selectWidget: {
      default: () => {},
    },
  },
  data() {
    return {
      draggable: true,
      element: null,
    };
  },
  watch: {},
  methods: {
    onActivated(element) {
      this.$emit('update:selectWidget', element);
    },
    onResize(x, y, w, h) {
      this.selectWidget.left = x;
      this.selectWidget.top = y;
      this.selectWidget.width = w;
      this.selectWidget.height = h;
    },
    onDrag(x, y) {
      this.selectWidget.left = x;
      this.selectWidget.top = y;
    },
    onDeactivated() {
      this.$emit('update:selectWidget', null);
    },
    handleDbClick(element) {
      this.element = element;
      this.draggable = false;
      this.$set(element, 'contenteditable', true);
      this.$emit('update:selectWidget', element);
    },
    handleChangeText(e) {
      setTimeout(() => {
        this.draggable = true;
        this.$set(this.element, 'contenteditable', false);
        this.$set(this.element, 'text', e.target.innerHTML);
      }, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vdr {
  border: 1px solid #e7e7e7;
}
::v-deep .draggable.active,
::v-deep .handle {
  border: 1px dashed var(--main-color-primary);
}
::v-deep .handle {
  background: #e7e7e7;
}
div:focus{
  outline: 0;
}

img {
  width: 100%;
  height: 100%;
}
.audio {
  border-radius: 15px;
  background-image: url('../../../assets/img/audio.svg');
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-animation: rotating 1.2s linear infinite;
  animation: rotating 1.2s linear infinite;
}
@-webkit-keyframes rotating{
  0%{transform:rotate(0deg)}
  to{transform:rotate(1turn)}
}
@keyframes rotating{
  0%{transform:rotate(0deg)}
  to{transform:rotate(1turn)}
}
</style>
