<template>
  <div>
    <el-tabs v-model="active" type="card"  v-if="element.type">
      <el-tab-pane label="基础" name="JC">
        <el-form label-position="top">
          <!-- 文字 -->
          <template v-if="element.type == 'text'">
            <el-form-item label="文本颜色">
              <div data-flex="cross:center">
                <el-color-picker v-model="element.fontColor"></el-color-picker>
                <span class="ml-10">{{ element.fontColor }}</span>
                <i
                  v-if="element.fontColor != ''"
                  class="el-icon-circle-close ml-10"
                  @click="element.fontColor = ''">
                </i>
              </div>
            </el-form-item>
            <el-form-item label="对齐方式">
              <el-select v-model="element.textAlign" class="w100" filterable clearable>
                <el-option
                    :value="ops.value"
                    :label="ops.label"
                    v-for="(ops, index) in textAlign"
                    :key="index">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="文字大小">
              <el-slider v-model="element.fontSize" show-input></el-slider>
            </el-form-item>
            <el-form-item label="行高">
              <el-slider v-model="element.lineHeight" show-input></el-slider>
            </el-form-item>
          </template>
          <template v-if="['text', 'block'].includes(element.type)">
            <el-form-item label="背景颜色">
              <div data-flex="cross:center">
                <el-color-picker v-model="element.bgColor"></el-color-picker>
                <span class="ml-10">{{ element.bgColor }}</span>
                <i
                  v-if="element.bgColor != ''"
                  class="el-icon-circle-close ml-10"
                  @click="element.bgColor = ''">
                </i>
              </div>
            </el-form-item>
          </template>
          <!-- img -->
          <template v-if="element.type == 'image'">
            <el-form-item label="图片">
              <el-input :clearable="true" placeholder="请上传图片" v-model="element.url">
                <el-button slot="append" icon="el-icon-upload2" @click="$refs.file.click()"></el-button>
              </el-input>
              <input @change="handleFileChange" ref="file" type="file" name="file" :multiple="false" class="el-upload__input">
            </el-form-item>
            <el-form-item label="图片填充方式">
              <el-select v-model="element.objFit" class="w100" filterable clearable>
                <el-option
                    :value="ops.value"
                    :label="ops.label"
                    v-for="(ops, index) in objFit"
                    :key="index">
                  </el-option>
              </el-select>
            </el-form-item>
          </template>
          <!-- media -->
          <template v-if="['video', 'audio'].includes(element.type)">
            <el-form-item label="自动播放">
              <el-switch v-model="element.autoplay"></el-switch>
            </el-form-item>
            <el-form-item label="循环">
              <el-switch v-model="element.loop"></el-switch>
            </el-form-item>
            <el-form-item label="控件">
              <el-switch v-model="element.controls"></el-switch>
            </el-form-item>
            <el-form-item label="地址">
              <el-input :clearable="true" placeholder="请上传文件" v-model="element.url">
                <el-button slot="append" icon="el-icon-upload2" @click="$refs.media.click()"></el-button>
              </el-input>
              <input @change="handleMediaChange" ref="media" type="file" name="file" :multiple="false" class="el-upload__input">
            </el-form-item>
          </template>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="动画" name="DH">
        <el-form label-position="top">
          <el-form-item label="动画方式">
            <el-select v-model="element.animation" class="w100" filterable clearable>
              <el-option
                  :value="ops.value"
                  :label="ops.label"
                  v-for="(ops, index) in animation"
                  :key="index">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="动画延迟时间">
            <el-select v-model="element.animationDelay" class="w100" filterable clearable>
              <el-option
                  :value="ops.value"
                  :label="ops.label"
                  v-for="(ops, index) in animationDelay"
                  :key="index">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="动画完成时间">
            <el-select v-model="element.animationDuration" class="w100" filterable clearable>
              <el-option
                  :value="ops.value"
                  :label="ops.label"
                  v-for="(ops, index) in animationDuration"
                  :key="index">
                </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="动画次数">
            <el-select v-model="element.animationRepeat" class="w100" filterable clearable>
              <el-option
                  :value="ops.value"
                  :label="ops.label"
                  v-for="(ops, index) in animationRepeat"
                  :key="index">
                </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="其他" name="QT">
        <el-form label-position="top">
          <el-form-item label="透明" v-if="element.opacity != undefined">
            <el-slider v-model="element.opacity" :max="1" :step="0.1" show-input></el-slider>
          </el-form-item>
          <el-form-item label="层级">
            <el-slider v-model="element.z" show-input></el-slider>
          </el-form-item>
          <el-form-item label="宽">
            <el-slider v-model="element.width" :max="320" show-input></el-slider>
          </el-form-item>
          <el-form-item label="高">
            <el-slider v-model="element.height" :max="568" show-input></el-slider>
          </el-form-item>
          <el-form-item label="X">
            <el-slider v-model="element.left" :max="999" show-input></el-slider>
          </el-form-item>
          <el-form-item label="Y">
            <el-slider v-model="element.top" :max="999" show-input></el-slider>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { animationOptions } from './animation';

export default {
  props: {
    element: {
      default: () => {},
    },
  },
  data() {
    return {
      active: 'JC',
      textAlign: [
        { label: '居左', value: 'left' },
        { label: '居中', value: 'center' },
        { label: '居右', value: 'right' },
      ],
      objFit: [
        { label: '拉伸', value: 'fill' },
        { label: '缩放', value: 'contain' },
        { label: '裁切', value: 'cover' },
        { label: '无', value: 'none' },
        { label: '取小位置', value: 'scale-down' },
      ],
      fontFm: [
        { label: '无', value: '' },
        { label: '雅黑', value: 'Microsoft YaHei' },
        { label: '行楷', value: 'KaiTi' },
        { label: '黑体', value: 'SimHei' },
      ],
      animation: [],
      animationRepeat: [
        { label: '1次', value: '1' },
        { label: '2次', value: '2' },
        { label: '3次', value: '3' },
        { label: '循环', value: 'infinite' },
      ],
      animationDelay: [
        { label: '0', value: '0' },
        { label: '0.1秒', value: '01' },
        { label: '0.2秒', value: '02' },
        { label: '0.3秒', value: '03' },
        { label: '0.5秒', value: '05' },
        { label: '0.8秒', value: '08' },
        { label: '1秒', value: '1' },
        { label: '2秒', value: '2' },
        { label: '3秒', value: '3' },
        { label: '4秒', value: '4' },
        { label: '5秒', value: '5' },
      ],
      animationDuration: [
        { label: '0.1秒', value: '01' },
        { label: '0.2秒', value: '02' },
        { label: '0.3秒', value: '03' },
        { label: '快0.5秒', value: 'faster' },
        { label: '快0.8秒', value: 'fast' },
        { label: '慢2秒', value: 'slow' },
        { label: '慢3秒', value: 'slower' },
      ],
    };
  },
  mounted() {
    animationOptions.forEach((el) => {
      this.animation.push(...el.children);
    });
  },
  methods: {
    handleFileChange(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (results) => {
        this.element.url = results.target.result;
        this.element.file = file;
        this.$refs.file.setAttribute('type', 'text');
        this.$refs.file.setAttribute('type', 'file');
      };
    },
    async handleMediaChange(e) {
      const file = e.target.files[0];
      const form = new FormData();
      form.append('files[0]', file);
      const config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 0,
      };
      this.$refs.media.setAttribute('type', 'text');
      this.$refs.media.setAttribute('type', 'file');
      this.$baseHttp.post('/attachment/upload', form, config).then((response) => {
        if (!response) return;
        this.element.url = response.data[0].file;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-icon-circle-close {
  font-size: 20px;
  cursor: pointer;
  color: var(--main-color-danger);
}
::v-deep .el-form {
  padding: 0 10px;
}
</style>
