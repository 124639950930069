var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100%","background":"linear-gradient(-90deg, rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px, linear-gradient(rgba(0, 0, 0, 0.1) 1px, transparent 1px) 0% 0% / 10px 10px"}},_vm._l((_vm.config.list),function(element){return _c('vue-draggable-resizable',{key:element.key,ref:"draggable",refInFor:true,attrs:{"w":element.width,"h":element.height,"x":element.left,"y":element.top,"z":element.z,"grid":[10, 10],"draggable":_vm.draggable,"parent":true},on:{"dragging":_vm.onDrag,"resizing":_vm.onResize,"activated":function($event){return _vm.onActivated(element)}}},[(element.type == 'text')?_c('div',{staticClass:"animate__animated",class:[
      'animate__'+element.animation,
      element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
      'animate__delay-'+element.animationDelay+'s',
      'animate__'+element.animationDuration],style:({
        opacity: element.opacity,
        backgroundColor: element.bgColor,
        fontSize: element.fontSize + 'px',
        color: element.fontColor,
        lineHeight: element.lineHeight + 'px',
        textAlign: element.textAlign,
      }),attrs:{"contenteditable":element.contenteditable || false},domProps:{"innerHTML":_vm._s(element.text)},on:{"dblclick":function($event){return _vm.handleDbClick(element)},"blur":_vm.handleChangeText}}):_vm._e(),(element.type == 'block')?_c('div',{staticClass:"animate__animated",class:[
      'animate__'+element.animation,
      element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
      'animate__delay-'+element.animationDelay+'s',
      'animate__'+element.animationDuration],style:({
        width: '100%',
        height: '100%',
        opacity: element.opacity,
        backgroundColor: element.bgColor,
      })}):_vm._e(),(element.type == 'image')?_c('div',{staticClass:"animate__animated",class:[
      'animate__'+element.animation,
      element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
      'animate__delay-'+element.animationDelay+'s',
      'animate__'+element.animationDuration],style:({
        width: '100%',
        height: '100%',
        opacity: element.opacity,
      })},[_c('img',{style:({
          objectFit: element.objFit,
        }),attrs:{"src":element.url}})]):_vm._e(),(element.type == 'video')?_c('div',{staticClass:"animate__animated",class:[
      'animate__'+element.animation,
      element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
      'animate__delay-'+element.animationDelay+'s',
      'animate__'+element.animationDuration],style:({
        width: '100%',
        height: '100%',
      })},[_c('video',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":element.url,"controls":element.controls,"loop":element.loop,"autoplay":element.autoplay}})]):_vm._e(),(element.type == 'audio')?_c('div',{staticClass:"animate__animated audio",class:[
      'animate__'+element.animation,
      element.animationRepeat == 'infinite' ? 'animate__infinite' : 'animate__repeat-'+element.animationRepeat,
      'animate__delay-'+element.animationDelay+'s',
      'animate__'+element.animationDuration],style:({
        width: '100%',
        height: '100%',
      })},[_c('audio',{attrs:{"src":element.url,"loop":element.loop,"autoplay":element.autoplay}})]):_vm._e()])}),1)}
var staticRenderFns = []

export { render, staticRenderFns }