import logo from '@/assets/img/logo-2.png';

export default [
  {
    name: '文字',
    type: 'text',
    icon: 'ri-file-text-line',
    text: '请输入内容',
    fontSize: 12,
    fontColor: '#000',
    textAlign: 'left',
    lineHeight: 20,
    fontFamily: '',
    opacity: 1,
    bgColor: '',
    width: 100,
    height: 100,
    left: 0,
    top: 0,
    animation: '',
    animationRepeat: '1',
    animationDelay: '0',
    animationDuration: 'faster',
  },
  {
    name: '图片',
    type: 'image',
    icon: 'ri-image-line',
    url: logo,
    file: null,
    objFit: 'contain',
    opacity: 1,
    width: 100,
    height: 100,
    left: 0,
    top: 0,
    animation: '',
    animationRepeat: '1',
    animationDelay: '0',
    animationDuration: 'faster',
  },
  {
    name: '视频',
    type: 'video',
    icon: 'ri-video-line',
    url: 'https://www.runoob.com/try/demo_source/movie.mp4',
    autoplay: false,
    loop: true,
    controls: false,
    width: 320,
    height: 200,
    left: 0,
    top: 0,
    animation: '',
    animationRepeat: '1',
    animationDelay: '0',
    animationDuration: 'faster',
  },
  {
    name: '音频',
    type: 'audio',
    icon: 'ri-netease-cloud-music-fill',
    url: 'https://res1.eqh5.com/group3/M00/21/78/yq0KZFXNTjWAWfU7AAZDIDmhLNk488.mp3',
    autoplay: true,
    loop: true,
    width: 30,
    height: 30,
    left: 0,
    top: 0,
    animation: '',
    animationRepeat: '1',
    animationDelay: '0',
    animationDuration: 'faster',
  },
  {
    name: '块',
    type: 'block',
    icon: 'ri-table-2',
    text: '请输入内容',
    opacity: 1,
    bgColor: '#ccc',
    width: 100,
    height: 100,
    left: 0,
    top: 0,
    animation: '',
    animationRepeat: '1',
    animationDelay: '0',
    animationDuration: 'faster',
  },
];
