<template>
  <el-form label-position="top">
    <el-form-item label="背景颜色">
      <div data-flex="cross:center">
        <el-color-picker v-model="config.bgColor"></el-color-picker>
        <span class="ml-10">{{ config.bgColor }}</span>
         <i
          v-if="config.bgColor != ''"
          class="el-icon-circle-close ml-10"
          @click="config.bgColor = ''">
        </i>
      </div>
    </el-form-item>
    <el-form-item label="背景图片">
      <el-input :clearable="true" placeholder="请上传图片" v-model="config.bgImage">
        <el-button slot="append" icon="el-icon-upload2" @click="$refs.file.click()"></el-button>
      </el-input>
      <input @change="handleFileChange" ref="file" type="file" name="file" :multiple="false" class="el-upload__input">
    </el-form-item>
    <template v-if="config.bgImage != ''">
      <el-form-item label="背景是否平铺">
        <el-select v-model="config.bgRepeat" class="w100" filterable clearable>
          <el-option
              :value="ops.value"
              :label="ops.label"
              v-for="(ops, index) in repeat"
              :key="index">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="背景对齐方式">
        <el-select v-model="config.bgPosition" class="w100" filterable clearable>
          <el-option
              :value="ops.value"
              :label="ops.label"
              v-for="(ops, index) in position"
              :key="index">
            </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="背景填充方式">
        <el-select v-model="config.bgSize" class="w100" filterable clearable>
          <el-option
              :value="ops.value"
              :label="ops.label"
              v-for="(ops, index) in size"
              :key="index">
            </el-option>
        </el-select>
      </el-form-item>
    </template>
  </el-form>
</template>

<script>
export default {
  props: {
    config: {
      default: () => {},
    },
  },
  data() {
    return {
      repeat: [
        { label: '不平铺', value: 'no-repeat' },
        { label: '平铺', value: 'repeat' },
      ],
      position: [
        { label: '左上', value: 'top left' },
        { label: '左中', value: 'top center' },
        { label: '左下', value: 'top right' },
        { label: '中左', value: 'center left' },
        { label: '居中', value: 'center center' },
        { label: '中右', value: 'center right' },
        { label: '下左', value: 'bottom left' },
        { label: '下中', value: 'bottom center' },
        { label: '下右', value: 'bottom right' },
      ],
      size: [
        { label: '自动', value: 'auto' },
        { label: '填充', value: 'cover' },
        { label: '自适应', value: 'contain' },
      ],
    };
  },
  methods: {
    handleFileChange(e) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (results) => {
        this.config.bgImage = results.target.result;
        this.config.bgFile = file;
        this.$refs.file.setAttribute('type', 'text');
        this.$refs.file.setAttribute('type', 'file');
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-size: 12px;
}
</style>
<style lang="scss" scoped>
.el-icon-circle-close {
  font-size: 20px;
  cursor: pointer;
  color: var(--main-color-danger);
}
</style>
